import React from "react";

import { Container } from "react-bootstrap";
import styles from "./style.module.css";

const About = () => {
  return (
   <div>
    <div className={styles.Header}></div>
    <p>Hello World</p>
   </div>
  );
};

export default About;
